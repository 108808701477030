import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

export default function About({ data }) {
  return (
    <Layout>
      <h1>About {data.site.siteMetadata.title}</h1>
      <p>
        Our goal is to provide a first class experience for authors to create
        content that can't be consumed or presented this way anywhere else.
        We'll provide you with all the tools you need to succeed: A great
        editor, a beautiful presentation, access to content consumers, search
        optimization, and even monetization. Even better, this garden has no
        walls - we provide you everything needed to take your content and
        publish elsewhere if you desire. Our goal is to help you succeed and to
        help people find your content, wherever you want it to live. You may
        write about fantasies, but achieving your goals shouldn't be just a
        fantasy, make it happen and let Cosmic Puma be a tool to help!
      </p>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
